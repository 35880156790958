import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'
import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import PageButtons from '../components/page-buttons'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'
import PageCtaFeature from '../components/page-cta-feature'

const mapDispatchToProps = dispatch => ({})

const LandscapeImage1 = 'gallery/happy-haus-wollumbin-11.jpg'

const Guide1Image = 'gallery/happy-haus-helensvale-13.jpg'
const Guide2Image = 'gallery/happy-haus-helensvale-11.jpg'

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'knockdown':
        dispatch(
          setLightboxForm({
            category: 'Guide Knockdown Rebuild',
            title: 'Get your free guide<br />to knockdown and rebuild',
            redirect: '/building-guides-thank-you',
          })
        )
        break

      case 'new':
        dispatch(
          setLightboxForm({
            category: 'Build Guide New',
            title: 'Get your free guide<br />to building your new home',
            redirect: '/building-guides-thank-you',
          })
        )
        break

      default:
        break
    }
  }

  render() {
    const CtaFeatures = [
      {
        title: 'New home<br />building guide',
        subTitle:
          'Love the location but want a new home? <br />Considering knockdown rebuild?',
        image: Guide1Image,
        buttonLabel: 'Download building guide',
        bgColor: '#e8e8e8',
        bgColorHover: '#e3e3e3',
        onClick: e => {
          this._handleRequestClick(e, 'new')
        },
      },
      {
        title: 'Knockdown<br />rebuild guide',
        subTitle: 'Looking to secure a block of land? <br />Ready to build?',
        image: Guide2Image,
        buttonLabel: 'Download building guide',
        bgColor: '#f1f1f1',
        bgColorHover: '#eeeeee',
        onClick: e => {
          this._handleRequestClick(e, 'knockdown')
        },
      },
    ]

    return (
      <>
        <SEO
          title="Building Guides"
          description="If you’re planning to build a new home, it’s important that
                  you research your options thoroughly – after all, it’s a
                  significant investment both financially and emotionally.
                  Remember, the builder you choose will ultimately be
                  responsible for creating your new home and bringing your
                  aspirations to life."
          image={LandscapeImage1}
        />

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage1} ratio={1 / 1.5} />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title>The perfect place to start</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={1} mr={[0, 0]}>
              <Copy align="left">
                <p>
                  If you’re planning to build a new home, it’s important that
                  you research your options thoroughly – after all, it’s a
                  significant investment both financially and emotionally.
                  Remember, the builder you choose will ultimately be
                  responsible for creating your new home and bringing your
                  aspirations to life.
                </p>
                <p>
                  Investing with a qualified and reputable builder will give you
                  peace of mind throughout the whole process. Working with a
                  builder who is prepared to listen is crucial.
                </p>
                <p>
                  We’ve created a FREE step-by-step guide to each stage of the
                  process.
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>

        <PageCtaFeature features={CtaFeatures} />

        <Row>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage1} ratio={1 / 1.5} />
            </Box>
          </Flex>
        </Row>

        <Row>
          <Flex flexWrap="wrap" flexDirection="row">
            <Box width={[1, 5 / 12]} px={2} mr={[0, 100]}>
              <Image src={LandscapeImage1} alt="" />
            </Box>
            <Box width={[1, 6 / 12]} px={0} mr={[0, 0]}>
              <Title>
                <p>Considered living by Happy Haus.</p>
              </Title>
              <Copy align="left">
                <p>
                  A Happy Haus is beautiful yet practical. It is a home that
                  engages with its surrounds, and maximises the ability to enjoy
                  the benefits of life in the subtropics. While on the inside,
                  it is complete with stunning interior design and carefully
                  considered luxury standard inclusions.
                </p>
              </Copy>
              <PageButtons
                align="left"
                data={[
                  {
                    label: 'Contact our team',
                    link: '/contact',
                    backgrounHover: theme.colors.buttonHover,
                    background: theme.colors.grey3,
                  },
                ]}
              />
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
